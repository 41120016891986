<template>
    <div class="w-full h-14 absolute z-10 bg-primary-300/30 backdrop-blur-sm bottom-0 left-0 right-0 flex flex-row justify-between items-center">

        <div class="py-2 flex-1 overflow-x-auto overflow-y-hidden flex flex-row gap-2 px-2">
            <MinWindow v-for="win in getActiveWindow()" :key="win.slug" :title="win.title" :icon="win.icon" :active="win.focused" @click="setFocusedWindow(win.slug)" @contextmenu.prevent="(e) => showContextMenu(e, win.slug)"/>
        </div>

        <h1 class="text-secondary font-secondary text-xl mr-5 hidden sm:inline-block">{{date}}</h1>

        <ContextMenu v-if="contextMenu" :slug="contextMenuWindow" :x="contextMenuPosition.x" :y="contextMenuPosition.y" @close="contextMenu = false"/>
    </div>
</template>

<script lang="ts" setup>
import { useWindowStore } from '@/stores/window.store';
import moment from 'moment';

const {setFocusedWindow, getActiveWindow} = useWindowStore();

const date = ref(moment().format('DD/MM/YYYY HH:mm'));

if(process.client) {
    setInterval(() => {
        date.value = moment().format('DD/MM/YYYY HH:mm');
    }, 1000);
}

const contextMenu = ref(false);
const contextMenuWindow = ref('');
const contextMenuPosition = ref({x: 0, y: 0});

const showContextMenu = (e: MouseEvent, slug: string) => {
    contextMenuPosition.value = {x: e.clientX, y: e.clientY}
    contextMenu.value = true;
    contextMenuWindow.value = slug;
}
</script>