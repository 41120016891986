<template>
    <div 
        class="window absolute bg-primary-100 rounded-lg shadow-outline overflow-y-hidden top-0 left-0 right-0 bottom-0 m-auto transition-window duration-200 flex flex-col" 
        :class="{'opacity-50': !win.focused, '!hidden': win.minimized}" 
        :style="{'translate': `${!win.maximized ? win.x : '0'}px ${!win.maximized ? win.y : -56/2}px`, 'width': !win.maximized ? win.width+'px' : '100%', 'height': !win.maximized ? win.height+'px' : 'calc(100% - 56px)', zIndex: win.order}" 
        @mousedown="onClick" 
        ref="windowBlock"
    >
        <WindowHeader :class="{'!cursor-grabbing': isOnMove, '!cursor-grab': !isOnMove}" :title="title" @close="win.opened = false" @maximize="onMaximize" @minimize="onMinimize" @mousedown="onMoveStart" @touchstart="onMoveStart"/>
        <div class="w-full max-h-full h-full overflow-auto">
            <div class="overflow-auto min-h-full h-full max-h-none" :class="{'px-5 py-2.5': padding}">
                <slot></slot>
            </div>
        </div>

        <!-- <div class="absolute h-8 w-8 bg-primary-300 rotate-45 bottom-0 right-0 cursor-nw-resize translate-x-1/2 translate-y-1/2" @mousedown="onResizeStart" @touchstart="onResizeStart"></div> -->
    </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import {useBreakpoints, breakpointsTailwind} from '@vueuse/core'

const {slug} = withDefaults(defineProps<{title: string, slug: string, padding?: boolean}>(), {
    padding: true
});

const {windows} = storeToRefs(useWindowStore());
const {setFocusedWindow} = useWindowStore();

const win = windows.value.find((w) => w.slug === slug);
const windowBlock = ref<HTMLDivElement>();

if(!win) throw new Error('Window not found');

const breakpoints = useBreakpoints(breakpointsTailwind);
const smAndSmaller = breakpoints.smaller('sm');

const onClick = () => {
    if(win.focused) return;

    setFocusedWindow(win.slug);
}

const onMaximize = () => {
    if(smAndSmaller.value) return;
    
    win.maximized = !win.maximized;
}

const onMinimize = () => {
    setFocusedWindow('');
    win.minimized = !win.minimized;
}


onMounted(() => {
    if(smAndSmaller.value) {
        win.maximized = true;
    }
})

watch(smAndSmaller, (v, o) => {
    if (v && v != o && !win.maximized) {
        win.maximized = true;
    }
})

const isOnMove = ref(false);

const mouseOffset = ref({x: 0, y: 0});

const onMoveStart = (e: MouseEvent|TouchEvent) => {
    e.preventDefault();

    if(win.maximized) return;

    isOnMove.value = true;

    if(e instanceof TouchEvent)  {
        const touch = e.touches[0];
        mouseOffset.value.x = touch.clientX - win.x;
        mouseOffset.value.y = touch.clientY - win.y;
    }
    else {
        mouseOffset.value.x = e.clientX - win.x;
        mouseOffset.value.y = e.clientY - win.y;
    }

    window.addEventListener('mousemove', onMove, {passive: true});
    window.addEventListener('touchmove', onMove, {passive: true});

    window.addEventListener('mouseup', onMoveEnd, {passive: true});
    window.addEventListener('touchend', onMoveEnd, {passive: true});
}

const onMove = (e: MouseEvent|TouchEvent) => {
    if(!isOnMove.value) return;

    if(e instanceof MouseEvent) {
        win.x = e.clientX - mouseOffset.value.x;
        win.y = e.clientY - mouseOffset.value.y;
    }
    else {
        const touch = e.touches[0];
        win.x = touch.clientX - mouseOffset.value.x;
        win.y = touch.clientY - mouseOffset.value.y;
    }
}

const onMoveEnd = () => {
    isOnMove.value = false;

    window.removeEventListener('mousemove', () => {});
    window.removeEventListener('mouseup', () => {});
    window.addEventListener('touchend', () => {});
    window.addEventListener('touchmove', () => {});
}
</script>

