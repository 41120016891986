<template>
    <Window :class="{'hidden': !win.opened, 'block': win.opened}" :padding="false" :title="win.title" :slug="win.slug">
        <div class="w-full h-full bg-[##282c34]">
            <pre><code class="whitespace-pre-wrap" v-html='codeToHtml?.value'></code></pre>
        </div>
    </Window>
</template>

<script setup lang="ts">
import hljs, { HighlightResult } from 'highlight.js';
import Typescript from 'highlight.js/lib/languages/typescript';
import 'highlight.js/styles/atom-one-dark.css';

import { useWindowStore } from '@/stores/window.store';
import { storeToRefs } from 'pinia';

const {windows} = storeToRefs(useWindowStore());
const win = windows.value.find((w) => w.slug === 'cv');

if(!win) throw new Error('Window not found');

const code = `
interface Experience {
    entreprise: string,
    poste: string,
    position: string
    date: {debut: string, fin?: string},
}

interface Formation {
    etablissement: string,
    titre: string,
    position: string,
}

export class Matheo {
    private nom: string;
    private prenom: string;

    private position: string;
    private age: number;

    /*
        Développeur autodidacte depuis plusieurs années, 
        les technologies les plus fréquemment utilisées sont 
        NestJS pour la partie serveur ainsi que NuxtJS pour la 
        partie client.
    */

    constructor() {
        this.nom = 'Boulanger';
        this.prenom = 'Matheo';

        this.position = 'France, Pas-de-Calais, Audruicq';
        this.age = 22;
    }

    experience(): Experience[] {
        return [
            {
                entreprise: 'Les Anges Gardins',
                poste: 'Employé polyvalent',
                position: 'France, Pas-de-Calais, Vieille Eglise',
                date: {debut: '04/03/2023'}
            },
            {
                entreprise: 'Astra Zeneca',
                poste: 'Stagiaire en maintenance',
                position: 'France, Pas-de-Calais, Dunkerque',
                date: {debut: '2015', fin: '2015'}
            }
        ]
    }

    formation(): Formation[] {
        return [
            {
                titre: "Bac professionnel technicien d'usinage",
                etablissement: 'Lycée Pierre De Coubertin',
                position: 'Calais'
            }
        ]
    }

    stack(): string[] {
        return [
            'Javascript', 'Typescript', 'HTML5', 'CSS3', 'Tailwind CSS', 'Node.Js', 'NestJS', 'Nuxt.js', 'Vue.js', 'Astro', 'PostgreSQL', 'MySQL', 'Prisma', 'Sequelize', 'Typeorm', 'Docker'
       ]
    }
}`;



const codeToHtml = ref<HighlightResult>();

onMounted(() => {
    hljs.registerLanguage('typescript', Typescript);
    
    codeToHtml.value = hljs.highlight(code, {language: 'typescript'});
    hljs.highlightAll();

})
</script>