<template>
    <Window :class="{'hidden': !win.opened, 'block': win.opened}" :title="project.title" :slug="project.slug">
        <div class="flex flex-row justify-between items-start p-2 border-b-2 border-primary-300 mb-2">
            <h1 class="font-primary text-secondary text-2xl">{{project.title}}</h1>

            <div class="flex flex-row justify-end items-center">
                <a v-if="project.github" :href="project.github" target="_blank" class="flex flex-row justify-center items-center gap-2 hover:bg-primary-300 rounded-md transition-colors duration-200 cursor-pointer p-2">
                    <Icon name="akar-icons:github-fill" class="h-4 w-4" color="#09244B" />
                    <span class="font-primary text-secondary uppercase text-base text-center">Github</span>
                </a>

                <a v-if="project.live" :href="project.live" target="_blank" class="flex flex-row justify-center items-center gap-2 hover:bg-primary-300 rounded-md transition-colors duration-200 cursor-pointer p-2">
                    <Icon name="akar-icons:link" class="h-6 w-6" color="#09244B" />
                    <span class="font-primary text-secondary uppercase text-base text-center">Live</span>
                </a>
            </div>
        </div>

        <SanityImage v-if="project.media && project.media" :asset-id="project.media" auto="format">
            <template #default="{src}">
                <img :src="src" class="w-full h-full object-cover object-center" loading="lazy"/>
            </template>
        </SanityImage>

        <video v-if="project.video" :src="project.video" controls class="w-full h-full" loading="lazy">
            <source :src="project.video" type="video">
        </video>

        <ClientOnly>
            <article class="!p-4 prose max-w-none">
                <PortableText v-if="project.description.length > 0" :value="project.description" :components="serializer"/>
            </article>
        </ClientOnly>

        <div class="flex flex-col justify-between items-start p-2 border-t-2 border-primary-300 mb-2">
            <h1 class="font-primary text-secondary text-2xl">Stack:</h1>
            <div class="flex flex-row justify-start items-center flex-wrap gap-2 pl-5 pt-2">
                <ul>
                    <li v-for="tech in project.tags" :key="tech" class="font-primary text-secondary text-base mb-2">{{tech}}</li>
                </ul>
            </div>
        </div>
    </Window>
</template>

<script setup lang="ts">
import {useFoldersStore} from '@/stores/folders.store';
import {useWindowStore} from '@/stores/window.store';
import { PortableText, PortableTextComponents } from '@portabletext/vue';
import hljs from 'highlight.js';
import javascript from 'highlight.js/lib/languages/javascript';
import typescript from 'highlight.js/lib/languages/typescript';
import 'highlight.js/styles/github.css';

const {slug} = defineProps<{slug: string}>();

const {findProject} = useFoldersStore();
const {getWindow} = useWindowStore();

const project = findProject(slug);
const win = getWindow(slug);

if(!win || !project) throw new Error('Window or project not found');

onMounted(() => {
    hljs.registerLanguage('javascript', javascript);
    hljs.registerLanguage('typescript', typescript);

    hljs.highlightAll();
});

const serializer: PortableTextComponents = {
    marks: {
        code: (_, {slots}) => {
            return h('pre', h('code', {innerHTML: hljs.highlightAuto(_.text).value}))
        },
    }
}
</script>