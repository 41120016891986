interface Grid {
    rows: number;
    columns: number;
    mines: number;

    cells: Cell[];
}

interface Cell {
    y: number;
    x: number;
    mine: boolean;
    revealed: boolean;
    flagged: boolean;
    adjacentMineCount: number;
    content: string;
}

enum CELL_CONTENT {
    MINE = '💣',
    FLAG = '🚩',
    EMPTY = '0️⃣',
    ONE = '1️⃣',
    TWO = '2️⃣',
    THREE = '3️⃣',
    FOUR = '4️⃣',
    FIVE = '5️⃣',
    SIX = '6️⃣',
    SEVEN = '7️⃣',
    EIGHT = '8️⃣',
}

interface State {
    status: 'new' | 'playing' | 'won' | 'lost';
    score: number;
}

export const useMineSweeperStore = defineStore('minesweeper', () => {
    const grid = ref<Grid>({rows: 0, columns: 0, mines: 0, cells: []});
    const state = ref<State>({status: 'new', score: 0});

    const timer = ref<number>(0);

    let interval: NodeJS.Timeout;

    const generateCells = (rows: number, columns: number, mines: number) => {
        for(let y = 0; y < rows; y++) {
            for(let x = 0; x < columns; x++) {
                grid.value.cells.push({y, x, mine: false, revealed: false, flagged: false, adjacentMineCount: 0, content: ''});
            }
        }

        for(let m = 0; m < mines; m++) {
            const randomCell = Math.floor(Math.random() * grid.value.cells.length);
            grid.value.cells[randomCell].mine = true;
        }
    }

    const start = (rows: number, columns: number, mines: number) => {

        grid.value.cells = [];
        timer.value = 0;

        grid.value.rows = rows;
        grid.value.columns = columns;
        grid.value.mines = mines;

        generateCells(rows, columns, mines);

        state.value.status = 'playing';

        interval = setInterval(() => {
            timer.value++;

            state.value.score = Math.round(((grid.value.cells.filter((cell) => cell.revealed).length / (grid.value.rows * grid.value.columns)) * 0.1 / timer.value * 10000));
        }, 1000);
    }

    const findCell = (x: number, y: number) => {
        return grid.value.cells.find((cell) => cell.y === y && cell.x === x);
    }

    const adjacentCells = (x: number, y: number) => {
        const cells = [];

        for(let dir_x = -1; dir_x <= 1; dir_x++) {
            for(let dir_y = -1; dir_y <= 1; dir_y++) {

                if(dir_x === 0 && dir_y === 0) {
                    continue;
                }

                const cell = findCell(x + dir_x, y + dir_y);

                if(cell) {
                    cells.push(cell);
                }
            }
        }

        return cells;
    }

    const revealCell = (x: number, y: number) => {
        const cell = findCell(x, y);

        if(!cell) {
            return;
        }

        cell.revealed = true;

        if(cell.mine) {
            cell.content = CELL_CONTENT.MINE;

            clearInterval(interval);

            grid.value.cells.forEach((cell) => {
                if(cell.mine) {
                    cell.content = CELL_CONTENT.MINE;
                    cell.revealed = true;
                }
            });

            setTimeout(() => {
                state.value.status = 'lost';
            }, 1500)

            return;
        }

        const nerbyCell = adjacentCells(x, y);

        cell.adjacentMineCount = nerbyCell.filter((cell) => cell.mine).length;

        if(cell.adjacentMineCount > 0) {
            cell.content = Object.values(CELL_CONTENT)[cell.adjacentMineCount + 2];
        } else {
            cell.content = CELL_CONTENT.EMPTY;
        }

        nerbyCell.forEach((c) => {
            if(c.mine) {
                const adj = adjacentCells(c.x, c.y);
                const countAdjReaveled = adj.filter((ce) => ce.revealed).length;
                const mineAdj = adj.filter((ce) => ce.mine).length;

                if(countAdjReaveled === adj.length - mineAdj) {
                    c.flagged = true;
                    c.content = CELL_CONTENT.FLAG;
                }

                return;
            }

            const mine = adjacentCells(c.x, c.y).filter((ce) => ce.mine).length;

            if(mine === 0 && c.adjacentMineCount === mine && !c.revealed) {
                revealCell(c.x, c.y);
            }
        });
    }

    const calculateMinesLeft = () => {
        return grid.value.mines - grid.value.cells.filter((cell) => cell.flagged).length;
    }

    return {
        start,
        findCell,
        revealCell,
        calculateMinesLeft,
        grid,
        state
    }
});