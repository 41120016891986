<template>
    <Teleport to="#page">
        <div class="bg-primary-100 rounded-lg shadow-outline absolute z-20 flex flex-col justify-center items-start overflow-hidden" :style="{'top': y-height+'px', 'left': x+'px'}" ref="context">
            <a class="text-secondary font-secondary font-bold cursor-pointer w-full px-5 py-2 hover:bg-primary-300" @click="onClose">Fermer</a>
            <a class="text-secondary font-secondary font-bold cursor-pointer w-full px-5 py-2 hover:bg-primary-300" @click="onResetPosition">Rénitialiser la position</a>
            <a class="text-secondary font-secondary font-bold cursor-pointer w-full px-5 py-2 hover:bg-primary-300" @click="onFocus">Mettre au premier plan</a>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
import { useElementBounding, onClickOutside } from '@vueuse/core';
import { useWindowStore } from '@/stores/window.store';

const {slug} = defineProps<{slug: string, x: number, y: number}>();

const {getWindow, setFocusedWindow} = useWindowStore();
const emit = defineEmits<{
    (event: 'close'): void
}>();

const context = ref<HTMLDivElement>();
const win = getWindow(slug);

if(!win) throw new Error('Window not found');

const {width, height} = useElementBounding(context);
onClickOutside(context, () => {
    emit('close');
});

const onClose = () => {
    win.opened = false;
    emit('close');
}

const onResetPosition = () => {
    win.x = 0;
    win.y = 0;
    emit('close');
}

const onFocus = () => {
    setFocusedWindow(win.slug);
    emit('close');
}
</script>