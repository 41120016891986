interface WindowData {
    opened: boolean;
    slug: string;
    width: number;
    height: number;
    minWidth: number;
    minHeight: number;
    x: number;
    y: number;
    minimized: boolean;
    maximized: boolean;
    fullscreen: boolean;
    focused: boolean;
    order: number;
    title: string;
    icon: string
}

export const useWindowStore = defineStore('win', () => {
    const router = useRouter();
    const windows = ref<WindowData[]>([]);

    const toggleWindow = (slug: string) => {
        const window = windows.value.find((window) => window.slug === slug);
        if (window) {
            window.opened = !window.opened;

            setFocusedWindow(slug);
        }
    }


    const setFocusedWindow = (slug: string) => {
        const window = windows.value.find((window) => window.slug === slug);

        windows.value.forEach((w) => {
            w.focused = false;
        });

        if (window) {
            window.focused = !window.focused;
            window.minimized = false;
            focusOrderWindow(slug);
        }
    }

    const focusOrderWindow = (slug: string) => {
        windows.value.forEach((window) => {
            if(window.slug === slug) {
                window.order = windows.value.length;
            } else {
                window.order == 0 ? 0 : window.order--;
            }
        });

        updateRouteWithOrder();
    }

    const updateRouteWithOrder = () => {
        const maxOrder = Math.max(...windows.value.map((window) => window.order));
        const first = windows.value.find((window) => window.opened && window.order == maxOrder);

        if(first) {
            router.replace({path: `/${first?.slug}`})
        }
        else {
            router.replace({path: `/`})
        }
    }

    const getWindow = (slug: string) => {
        return windows.value.find((window) => window.slug === slug);
    }

    const getActiveWindow = () => {
        return windows.value.filter((window) => window.opened);
    }

    const createWindow = (data: Pick<WindowData, 'slug'|'x'|'y'|'height'|'width'|'title'> & {minWidth?: number, minHeight?: number, icon?: string}) => {
        const {slug, width, height, minHeight, minWidth, x, y, title, icon} = data;
        const w: WindowData = {
            opened: false,
            slug,
            width,
            height,
            minWidth: minWidth ?? width,
            minHeight: minHeight ?? height,
            x,
            y,
            minimized: false,
            maximized: false,
            fullscreen: false,
            focused: true,
            order: windows.value.length,
            title,
            icon: icon ?? 'mingcute:folder-fill'
        }

        windows.value.push(w)
    }

    return {
        windows,
        toggleWindow,
        setFocusedWindow,
        focusOrderWindow,
        getWindow,
        createWindow,
        getActiveWindow
    }
        
});