<template>
    <div id="page" class="relative h-screen w-full max-h-screen max-w-full overflow-hidden">
        <div class="grid grid-end-auto grid-flow-dense auto-rows-max auto-cols-min gap-4 p-5 w-full h-screen overflow-hidden">
            <DesktopIcon v-for="folder in folders" :key="folder.slug" :title="folder.title" :icon="folder.icon" @click="toggleWindow(folder.slug)"/>

            <DesktopIcon :title="'Contact'" :icon="'mingcute:mail-fill'" @click="toggleWindow('contact')"/>
            <DesktopIcon :title="'CV.ts'" :icon="'mingcute:file-code-fill'" @click="toggleWindow('cv')"/>

            <DesktopIcon :title="'Démineur.exe'" :icon="'mingcute:game-2-fill'" @click="toggleWindow('minesweeper')"/>
        </div>

        <FolderWindow v-for="folder in folders" :key="folder.slug" :name="folder.title" :folder="folder"/>
        <ProjectWindow v-for="project in flatProject()" :key="project.slug" :slug="project.slug"/>

        <ContactWindow/>
        <CVWindow/>

        <TaskBar/>

        <!-- GAMES -->
        <ClientOnly>
            <MinesweeperWindow/>
        </ClientOnly>
    </div>
</template>

<script setup lang="ts">
import {useFoldersStore, Folder} from '@/stores/folders.store';
import { storeToRefs } from 'pinia';
import { useWindowStore } from '~/stores/window.store';

definePageMeta({
    key: 'index'
})

const nuxtApp = useNuxtApp();

const foldersStore = useFoldersStore()
const {flatProject} = foldersStore;
const {folders} = storeToRefs(foldersStore);

const route = useRoute();

const {createWindow, toggleWindow, getWindow, windows} = useWindowStore();

const query = groq`*[_type=='Folders'] {_id,title,icon,"slug":slug.current,"projects": *[_type=='Projects' && references(^._id)] {title,"slug": slug.current,'media': media.asset._ref,'video':video.asset->url,github,live,tags,description}}`;

if(!nuxtApp.isHydrating) {
    folders.value = await useSanity().fetch<Folder[]>(query);

    folders.value.forEach((folder) => {
        createWindow({
            slug: folder.slug,
            title: folder.title,
            height: 300,
            width: 600,
            x: 0,
            y: 0,
        });

        folder.projects.forEach((project) => {
            createWindow({
                slug: project.slug,
                title: project.title,
                height: 640,
                width: 1024,
                x: 0,
                y: 0,
            });
        });
    });

    createWindow({
        slug: 'contact',
        title: 'Contact',
        height: 580,
        width: 400,
        x: 0,
        y: 0,
        icon: 'mingcute:mail-fill'
    });

    createWindow({
        slug: 'cv',
        title: 'cv.ts',
        height: 640,
        width: 1024,
        x: 0,
        y: 0,
        icon: 'mingcute:file-code-fill'
    });

    createWindow({
        slug: 'minesweeper',
        title: 'Démineur',
        height: 640,
        width: 1024,
        x: 0,
        y: 0,
        icon: 'mingcute:game-2-fill'
    });
}

const {page} = route.params;

if(page && getWindow(page as string)?.opened === false) {
    toggleWindow(page as string);
}

const generateTitle = () => {
    if(page) {
        const win = getWindow(page as string);
        if(win) {
            return win.title.charAt(0).toUpperCase() + win.title.slice(1) + ' - Mathéo Boulanger';
        }
    }

    return 'Portfolio' + ' - Mathéo Boulanger';
}

const updateSeo = () => {
    useSeoMeta({
        title: generateTitle,
        description: 'Développeur autodidacte depuis plusieurs années, les technologies les plus fréquemment utilisées sont  NestJS pour la partie serveur ainsi que NuxtJS pour la partie client.',
        ogType: 'website',
        ogTitle: generateTitle,
        ogDescription: 'Développeur autodidacte depuis plusieurs années, les technologies les plus fréquemment utilisées sont  NestJS pour la partie serveur ainsi que NuxtJS pour la partie client.',
        ogImage: '/seo.webp',
        twitterCard: 'summary_large_image',
        twitterTitle: generateTitle,
        twitterImage: '/seo.webp',
        twitterDescription: 'Développeur autodidacte depuis plusieurs années, les technologies les plus fréquemment utilisées sont  NestJS pour la partie serveur ainsi que NuxtJS pour la partie client.',
        twitterSite: 'https://matheoboulanger.fr/'
    });
}

updateSeo();
</script>