import { defineStore, skipHydrate } from "pinia";

export interface Folder {
    _id: string;
    title: string;
    icon: string;
    slug: string,
    projects: Project[];
}

export interface Project {
    title: string,
    media?:string;
    video?: string;
    slug: string;
    github?: string;
    live?: string;
    tags: string[];
    description: any[];
}

export const useFoldersStore = defineStore('folders', () => {
    const folders = ref<Folder[]>([]);

    const addFolder = (folder: Folder) => {
        return folders.value.push(folder);
    }

    const findProject = (slug: string) => {
        return folders.value.map((folder) => folder.projects).flat().find((project) => project.slug === slug);
    }

    const flatProject = () => {
        return folders.value.map((folder) => folder.projects).flat();
    }

    return {
        folders,
        addFolder,
        findProject,
        flatProject
    }
})