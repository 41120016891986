<template>
    <Window :class="{'hidden': !win.opened, 'block': win.opened}" :title="win.title" :slug="win.slug" :padding="false">
        <div class="flex flex-col justify-center items-center w-fit min-w-full" v-if="state.status === 'playing'">
            <div class="stats w-full flex flex-col md:flex-row justify-between items-start p-8">
                <h1 class="font-primary text-secondary text-2xl">Stats:</h1>

                <div class="flex-1 flex flex-col md:flex-row justify-around items-start">
                    <div class="flex flex-row justify-start items-end space-x-2 border-b-2 border-primary-300">
                        <span class="font-primary text-secondary text-2xl">{{ game.calculateMinesLeft() }}</span>
                        <span class="font-primary text-secondary text-base">Mines restantes</span>
                    </div>

                    <div class="flex flex-row justify-start items-end space-x-2 border-b-2 border-primary-300">
                        <span class="font-primary text-secondary text-2xl">{{ grid.cells.filter((cell) => cell.revealed).length }}</span>
                        <span class="font-primary text-secondary text-base">Cases révélées</span>
                    </div>

                    <div class="flex flex-row justify-start items-end space-x-2 border-b-2 border-primary-300">
                        <span class="font-primary text-secondary text-2xl">{{ state.score }}</span>
                        <span class="font-primary text-secondary text-base">Points</span>
                    </div>
                </div>
            </div>

            <div class="w-full h-full gap-1 flex flex-row justify-center items-start p-1">
                <div class="gap-1 flex flex-col justify-center items-center" v-for="(row, x) in grid.rows">
                    <div class="h-5 w-5 md:h-10 md:w-10 text-white rounded-md text-center flex flex-col justify-center items-center text-sm md:text-lg" v-for="(col, y) in grid.cells.filter((cell) => cell.x === x)" :class="{'bg-green-500': col.flagged, 'bg-red-700': col.revealed && col.mine,  'bg-gray-700': !col.flagged && !col.revealed}" @click="printCoord(x, y)">{{ col.content }}</div>
                </div>
            </div>
        </div>

        <div class="flex flex-col justify-center items-center gap-5 h-full" v-else>

            <h1 class="font-primary text-secondary text-2xl uppercase">Le Démineur</h1>

            <h1 class="font-primary text-secondary text-2xl" v-if="state.status === 'won'">Vous avez gagné !</h1>
            <h1 class="font-primary text-secondary text-2xl" v-else-if="state.status === 'lost'">Vous avez perdu !</h1>
                
            <div class="flex flex-row justify-center items-center gap-5">
                <button class="font-primary text-secondary text-2xl uppercase hover:text-outline transition-all duration-75" @click="game.start(10, 10, 10)">Facile</button>
                <button class="font-primary text-secondary text-2xl uppercase hover:text-outline transition-all duration-75" @click="game.start(20, 20, 50)">Moyen</button>
                <button class="font-primary text-secondary text-2xl uppercase hover:text-outline transition-all duration-75" @click="game.start(30, 30, 100)">Difficile</button>
            </div>
        </div>
    </Window>
</template>

<script lang="ts" setup>

import { useWindowStore } from '@/stores/window.store';
import { useMineSweeperStore } from '@/stores/minesweeper.store';
import { storeToRefs } from 'pinia';

if(!process.client) {
    throw new Error('This component can only be used in the browser');
}

const {getWindow} = useWindowStore();
const game = useMineSweeperStore();

const {grid, state} = storeToRefs(game);

const printCoord = (x: number, y: number) => {
    if(state.value.status !== 'playing') return;

    game.revealCell(x, y);
}

const win = getWindow('minesweeper');

if(!win) {
    throw new Error('Window not found');
}

</script>