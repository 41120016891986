<template>
    <Window :class="{'hidden': !win.opened, 'block': win.opened}" :title="name" :slug="folder.slug">
        <div class="flex flex-row justify-start items-center flex-wrap gap-2">
            <DesktopIcon v-for="project in folder.projects" :key="project.slug" :title="project.title+'.pdf'" icon="mingcute:file-fill" @click="toggleWindow(project.slug)"/>
        </div>
    </Window>
</template>

<script setup lang="ts">
import { Folder } from '@/stores/folders.store';
import { useWindowStore } from '@/stores/window.store';
import { storeToRefs } from 'pinia';
const {folder} = defineProps<{name: string, folder: Folder}>();
const {toggleWindow} = useWindowStore();
const {windows} = storeToRefs(useWindowStore());
const win = windows.value.find((w) => w.slug === folder.slug);

if(!win) throw new Error('Window not found');

</script>
