<template>
    <Window :class="{'hidden': !win.opened, 'block': win.opened}" :title="win.title" :slug="win.slug">
        <form netlify name="contact" method="POST" action="/" class="flex flex-col gap-4 justify-start items-start w-full">
            <div class="w-full flex flex-row gap-4 bg-primary-300 text-secondary font-primary p-2 border border-primary-500">
                <label for="name" class="font-primary uppercase text-base">à :</label>
                <div class="fake-input">boulangermatheo62@gmail.com</div>
            </div>

            <div class="w-full flex flex-row gap-4 bg-primary-300 text-secondary font-primary p-2 border border-primary-500">
                <label for="name" class="font-primary text-secondary uppercase text-base">de :</label>
                <input type="email" name="email" id="email" class="flex-1 outline-none bg-transparent placeholder:text-primary-500" placeholder="Email" />
            </div>

            <div class="w-full flex flex-row gap-4 bg-primary-300 text-secondary font-primary p-2 border border-primary-500">
                <label for="name" class="font-primary text-secondary uppercase text-base">sujet :</label>
                <input type="text" name="subject" id="subject" class="flex-1 outline-none  bg-transparent placeholder:text-primary-500" placeholder="Sujet" />
            </div>

            <div class="w-full bg-primary-300 text-secondary font-primary p-2 border border-primary-500">
                <textarea name="message" id="message" class="outline-none  bg-transparent placeholder:text-primary-500 w-full" placeholder="Message" rows="10" ></textarea>
            </div>

            <button type="submit" class="w-full bg-green-300 border border-green-500 font-primary p-2 uppercase">envoyer</button>
        </form>
    </Window>
</template>

<script setup lang="ts">
import { useWindowStore } from '@/stores/window.store';
import { storeToRefs } from 'pinia';

const {windows} = storeToRefs(useWindowStore());
const win = windows.value.find((w) => w.slug === 'contact');

if(!win) throw new Error('Window not found');
</script>